import gql from 'graphql-tag'

export const UpdateFairStoreState = gql`
  mutation updateFairStoreState($input: UpdateFairStoreStateInput!) {
    updateFairStoreState(input: $input) {
      success
    }
  }
`

export const JoinFair = gql`
  mutation joinFair($input: JoinToFairInput!) {
    joinFair(input: $input) {
      success
    }
  }
`

export const LeaveFair = gql`
  mutation leaveFair($input: LeaveFairInput!) {
    leaveFair(input: $input) {
      success
    }
  }
`
