<template>
  <v-dialog
    v-model="dialog"
    width="500"
    >
    <v-card
      class="rounded-lg"
      >
      <v-card-title>
        <v-spacer></v-spacer>

        <v-btn
          @click="dialog = false"
          icon
          >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text
        class="px-6 pb-6 rounded-lg"
        >
        <div
          v-if="fair && currentUser && !loading"
          >
          <div
            v-if="currentUser.selectedProfile.profilableType == 'Store'"
            >
            <div
              class="text-center"
              >
              <v-icon
                size="50"
                color="primary"
                >
                mdi-string-lights
              </v-icon>

              <div
                class="mb-6 text-h5 font-weight-bold black--text"
                >
                {{ fair.storeIds.includes(currentUser.selectedProfile.profilableId) ? '¡Ya formas parte!' : '¡Únete a la feria!' }}
              </div>

              <div
                class="mb-6"
                style="position: relative"
                >
                <v-avatar
                  v-if="fair.image && fair.image.url"
                  color="primary"
                  size="130"
                  >
                  <v-img
                    :src="url + fair.image.url"
                    ></v-img>
                </v-avatar>

                <v-avatar
                  v-else
                  color="secondary"
                  size="130"
                  >
                  <v-icon
                    color="white"
                    size="60"
                    >
                    mdi-string-lights
                  </v-icon>
                </v-avatar>
              </div>

              <div
                class="text-h6 text-center mt-2 black--text font-weight-bold"
                >
                {{ fair.name }}
              </div>

              <div
                class="body-1 black--text"
                >
                {{ (new Date(fair.startsAt)).toLocaleDateString() }} al {{ (new Date(fair.endsAt)).toLocaleDateString() }}
              </div>

              <div
                class="body-1 black--text mt-2"
                >
                <v-icon color="primary" class="mt-n1">mdi-account</v-icon> {{ fair.owner }}
              </div>

              <div
                class="body-1 text-center mt-2 black--text"
                >
                {{ fair.description }}
              </div>

              <div
                class="body-1 text-center mt-2 white--text font-weight-medium px-3 py-2 primary mx-auto rounded-lg"
                style="width: fit-content"
                >
                Comisión del {{ fair.commission }}%
              </div>

              <v-btn
                block
                color="primary"
                @click="joinFair"
                class="mt-6 rounded-lg"
                large
                v-if="!fair.storeIds.includes(currentUser.selectedProfile.profilableId)"
                >
                ¡Unirme!
              </v-btn>

              <v-btn
                block
                large
                color="error"
                @click="leaveFair"
                class="mt-6 rounded-lg"
                depressed
                outlined
                v-else
                >
                Desinscribirme
              </v-btn>

              <v-btn
                small
                text
                block
                color="gray"
                @click="cancel"
                class="mt-3 rounded-lg"
                >
                Cerrar
              </v-btn>
            </div>
          </div>

          <div
            v-else
            >
            <change-profile
              ref="changeProfile"
              ></change-profile>

            <div
              class="text-center"
              >
              <v-icon
                size="50"
                color="info"
                >
                mdi-alert-circle
              </v-icon>

              <div
                class="black--text body-1 my-3"
                >
                Para continuar necesitas estar utilizando un perfil de <span class="font-weight-medium">Partner</span>.
              </div>

              <v-btn
                color="primary"
                depressed
                class="rounded-lg"
                @click="openChangeProfile"
                >
                Cambiar de perfil
              </v-btn>
            </div>
          </div>
        </div>

        <div
          v-if="!currentUser"
          >
          <div
            class="text-center"
            >
            <v-icon
              size="50"
              color="info"
              >
              mdi-alert-circle
            </v-icon>

            <div
              class="black--text body-1 my-3"
              >
              ¡Para continuar necesitas tener tu sesión iniciada!
            </div>

            <v-btn
              color="primary"
              block
              text
              outlined
              class="rounded-lg"
              @click="dialog = false"
              >
              Iniciar sesión
            </v-btn>

            <div
              class="body-1 text-center font-weight-medium my-2 black--text"
              >
              O
            </div>

            <v-btn
              color="primary"
              depressed
              block
              class="rounded-lg"
              @click="goToSignUp"
              >
              Registarte
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { Fair } from '@/graphql/queries/fairs/fairs'
import { JoinFair, LeaveFair } from '@/graphql/mutations/fairs/fair_stores'

const ChangeProfile = () => import('@/components/profiles/ChangeProfile')

export default {
  data: () => ({
    dialog: false,
    loading: true,
    id: null,
    fair: null,
    success: false
  }),

  created () {
    // this is extremely monkey

    var join = this.$route.query.join || (this.$route.query.redirect && this.$route.query.redirect.split('?')[1] ? (new URLSearchParams(this.$route.query.redirect.split('?')[1])).get('join') : null)

    if (join || localStorage.getItem('fairId')) {
      if (join)
        localStorage.setItem('fairId', join) 

      this.id = localStorage.getItem('fairId')

      this.fetchFair()

      if (this.currentUser && this.currentUser.firstSignIn) {
        this.dialog = false
      } else {
        this.dialog = true
      }
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'url'])
  },

  watch: {
    currentUser () {
      if (this.currentUser.firstSignIn)
        this.dialog = false
    }
  },

  methods: {
    fetchFair () {
      this.$apollo.query({
        query: Fair,
        variables: {
          id: this.id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.fair = res.data.fair
        this.loading = false
      })
    },

    joinFair () {
      this.$apollo.mutate({
        mutation: JoinFair,
        variables: {
          input: {
            id: this.id
          }
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.success = true
        this.fetchFair()
        localStorage.removeItem('fairId')
      })
    },

    leaveFair () {
      this.$apollo.mutate({
        mutation: LeaveFair,
        variables: {
          input: {
            id: this.id
          }
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.success = true
        this.fetchFair()
        localStorage.removeItem('fairId')
      })
    },

    cancel () {
      localStorage.removeItem('fairId')

      this.dialog = false
    },

    openChangeProfile () {
      this.$refs.changeProfile.dialog = true
    },

    goToSignUp () {
      this.dialog = false
      this.$router.push({ name: 'signup' })
    }
  },

  components: {
    ChangeProfile
  }
}
</script>
